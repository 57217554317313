import React, { useState, useContext, useEffect } from 'react';
import { authContext } from '../contexts/AuthContext';

import { FormLabel } from '../components/InviteFormComponents';
import { headerContext } from '../contexts/HeaderContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PhoneIcon,
  RocketLaunchIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useNetworkManager } from '../util/Network';
import { validatePhoneNumber } from '../util/Utils';

const FinishAccountSetupScreen = () => {
  const { firebaseUser, clearAuth, setFirebaseUser, setAuth } =
    useContext(authContext);
  const [displayName, setDisplayName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const { setBackButtonFn } = useContext(headerContext);
  const navigate = useNavigate();
  const { makePostRequest } = useNetworkManager();
  const { shareId } = useParams();

  useEffect(() => {
    setBackButtonFn(() => {
      clearAuth();
      navigate('/login');
      return null;
    });
    return () => {
      setBackButtonFn(null);
    };
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      setDisplayName(firebaseUser.displayName);
      setPhoneNumber(firebaseUser.phoneNumber);
    }
  }, [firebaseUser]);

  if (!firebaseUser) {
    return null;
  }
  let greetingString = 'Hi, ';
  if (displayName) {
    if (displayName.split(' ').length > 1) {
      const [firstName] = displayName.split(' ');
      greetingString += `${firstName}!`;
    } else {
      greetingString += `${displayName}!`;
    }
  } else {
    greetingString = `Hi there!`;
  }

  const onSubmit = async () => {
    // get firebase user id
    const firebaseId = firebaseUser.uid;
    const { response, error } = await makePostRequest(
      `auth/register/firebase`,
      {
        firebaseId,
        displayName,
        phoneNumber: validatePhoneNumber(phoneNumber),
        firebaseUser,
        shareId,
      }
    );
    console.log('/auth/register/firebase response & error', response, error);
    if (response) {
      setAuth(response.user, firebaseUser.accessToken);
      navigate('/');
    } else {
      console.error('Error registering user', error);
    }
  };

  const isFormComplete =
    displayName && phoneNumber && validatePhoneNumber(phoneNumber);

  return (
    <div className='bg-white rounded-md gap-y-3 p-5 mt-5 mx-3 flex flex-col items-center '>
      <div className='text-center'>
        <p className='text-lg'> {greetingString}</p>
        <p className='text-xs'>Verify details below to complete registration</p>
      </div>
      <div className='flex flex-col items-center w-full gap-y-4'>
        <FormInput
          Icon={PhoneIcon}
          label='Phone Number'
          value={phoneNumber || ''}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder='Phone Number'
        />
        <FormInput
          Icon={UsersIcon}
          label='Display Name'
          value={displayName || ''}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder='Display Name'
        />

        <button
          onClick={onSubmit}
          className={
            'flex flex-row gap-x-2 align-middle items-center mt-2 bg-blue-600 text-white p-2 rounded-md text-sm ' +
            (!isFormComplete && 'bg-gray-300 text-black cursor-not-allowed')
          }
          disabled={!isFormComplete}
        >
          <RocketLaunchIcon className='h-5 w-5' />
          Let's Go!
        </button>
      </div>
    </div>
  );
};

export { FinishAccountSetupScreen };

const FormInput = ({ Icon, label, value, onChange, placeholder }) => {
  return (
    <div className='flex flex-col gap-y-1  rounded-md px-3 py-1 w-3/4'>
      <FormLabel Icon={Icon} label={label} />
      <input
        type='text'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className='w-full border-gray-300 border p-2 rounded-md outline-none'
      />
    </div>
  );
};
