import React, { useState, useContext, useEffect, useMemo } from 'react';
import { InvitesContext, isInviteInPast } from '../contexts/InvitesContext';
import { getDateString, getTimeString } from '../util/Utils';
import { CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { authContext } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { FOOTER_HEIGHT } from '../util/Constants';

import { useNetworkManager } from '../util/Network';

export default function InviteScreen() {
  const { makeGetRequest } = useNetworkManager();
  const { shareId } = useParams();
  const { user } = useContext(authContext);
  const [invitesToRender, setInvitesToRender] = useState([]);

  useEffect(() => {
    makeGetRequest(`invites/share/info/${shareId}`).then(
      ({ response, error }) => {
        if (error) {
          console.error('Error fetching invite info: ', error);
          return;
        }
        console.log('`invites/share/info/ Result: ', response);
        setInvitesToRender([response]);
      }
    );
  }, []);

  return (
    <div style={{ marginBottom: FOOTER_HEIGHT }}>
      <div id='home-screen-body'>
        {invitesToRender?.map((invite) => {
          return <InviteCard key={invite.invite_id} invite={invite} />;
        })}
      </div>
    </div>
  );
}

const InviteCard = ({ invite }) => {
  const isAuthor = false;
  //   const isAuthor = invite.author.user_id === user.user_id;

  return (
    <div className='flex-col bg-white mt-4 p-4 rounded-xl grid space-y-1.5 relative'>
      {(!isAuthor && (
        <div>
          <div className='text-xs'>{invite.author.name} invites you to:</div>
          <ActivityTitleRow invite={invite} />
          <RespondToInviteRow invite={invite} />
        </div>
      )) || (
        <div className='text-xs'>
          Silly goose you can't invite yourself to your own event
        </div>
      )}
    </div>
  );
};

export const ActivityTitleRow = ({ invite }) => {
  return (
    <div className='font-semibold text-sm flex items-center justify-evenly'>
      <div className='flex-1'>{invite.activity}</div>
      {invite.cancelled_at_timestamp && (
        <div className='bg-red-100 p-2 text-red-600 text-xs font-semibold rounded-lg'>
          Cancelled
        </div>
      )}
    </div>
  );
};

export const RespondToInviteRow = ({ invite, onDeclineOverride }) => {
  const { user } = useContext(authContext);
  const navigate = useNavigate();
  const { makeGetRequest } = useNetworkManager();
  const { shareId } = useParams();

  const acceptInvite = (a) => {
    console.log('acceptInvite: ', a);
    const { response, error } = makeGetRequest(`invites/join/${shareId}`);

    if (error || !response) {
      console.error('Error accepting invite: ', error);
      console.log('navigating to login + response: ', response);
      navigate(`/login/${shareId}`);
      return;
    }
    console.log('acceptInvite response: ', response);
    const { inviteId } = response;

    navigate(`/i/${inviteId}`);
  };

  if (isInviteInPast(invite) || invite.cancelled_at_timestamp) {
    return null;
  }

  const participantRecord = invite.participants?.find(
    (p) => p.user_id === user.user_id
  );

  const isPending = !participantRecord?.status;
  console.log('participantRecord', participantRecord);
  console.log('user', user);
  console.log('isPending', isPending);
  console.log('invite', invite);
  const isConfirmed =
    `${participantRecord?.status}`.toLocaleLowerCase() === 'accepted';
  const isDeclined = participantRecord?.status === 'declined';

  return (
    <div className='grid grid-flow-col space-x-8 items-center text-xs font-semibold'>
      <button
        onClick={(e) => {
          e.stopPropagation();
          acceptInvite({ inviteId: invite.invite_id, userId: user.user_id });
        }}
        disabled={isDeclined}
        className={
          'border rounded-md p-2 ' +
          getAcceptButtonStyle(participantRecord?.status)
        }
      >
        Accept
      </button>
    </div>
  );
};

const getAcceptButtonStyle = (status) => {
  switch (status) {
    case 'declined':
      return ' bg-white text-blue-700 border-blue-700 border opacity-50';
    case 'confirmed':
      return ' bg-blue-100 text-blue-700 border-blue-700 border font-bold';
    default:
      return ' bg-white text-blue-700 border-blue-700';
  }
};
