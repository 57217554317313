import React from 'react';
import { useNetworkManager } from '../../util/Network';

export const MutualsView = ({
  mutuals,
  onContactClick,
  onAddMutualSuccess,
}) => {
  const { makePostRequest } = useNetworkManager();

  const addMutual = async (newMutualDetails) => {
    console.log('add mutual', newMutualDetails);
    const { response, error } = await makePostRequest(
      `contacts/mutuals/add`,
      newMutualDetails
    );
    if (error) {
      console.error('error', error);
      return;
    }

    console.log('response', response);
    onAddMutualSuccess();
  };

  console.log('mutuals', mutuals);
  const lettersToMutuals = {};
  mutuals?.forEach((mutual) => {
    const firstLetter = mutual.name[0].toUpperCase();
    if (!lettersToMutuals[firstLetter]) {
      lettersToMutuals[firstLetter] = [mutual];
    } else {
      lettersToMutuals[firstLetter].push(mutual);
    }
  });

  return (
    <>
      <div className='bg-white rounded-md h-full m-4 p-3'>
        {/* <div
          className='flex justify-between py-3 text-sm border-b-2'
          onClick={() => {
            console.log('open find / invite friends');
          }}
        >
          Find & Invite Friends
          <ChevronRightIcon className='h-5 w-5' />
        </div> */}

        {Object.keys(lettersToMutuals).map((letter, outerIndex) => {
          const isLastLetter =
            outerIndex === Object.keys(lettersToMutuals).length - 1;
          return (
            <div key={letter}>
              <div className='font-semibold  text-gray-400 text-base my-4'>
                {letter}
              </div>
              {lettersToMutuals[letter].map((mutual, index) => {
                const isLastItemInList =
                  index === lettersToMutuals[letter].length - 1;
                return (
                  <div
                    key={mutual.user_id + ' ' + index}
                    onClick={() => {
                      onContactClick(mutual);
                    }}
                    className={`flex flex-row justify-around items-center py-3 text-sm border-t-2 ${isLastItemInList && !isLastLetter && 'border-b-2'} ${index === 0 && 'border-t-2'} `}
                  >
                    <div className='flex-1'>{mutual.name}</div>
                    <button
                      className={`flex flex-row align-middle items-center bg-white text-blue-700 border rounded-md p-2 font-semibold border-blue-700`}
                      onClick={(e) => {
                        e.stopPropagation();
                        addMutual(mutual);
                      }}
                    >
                      Add
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContactSkeletonRow = () => {
  return (
    <div className='flex animate-pulse flex-row items-center py-5 text-sm font-medium border-t-2'>
      <div className='w-2/3 bg-gray-300 h-6 rounded-md '></div>
    </div>
  );
};
