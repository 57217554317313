export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
console.log('running in', process.env.NODE_ENV);

// create-react-app proxies requests to the backend server for us, so we don't
// need to specify the URL when running in development mode. see
// `proxy` in client/package.json
export const BASE_URL = IS_DEVELOPMENT
  ? 'http://localhost:61186'
  : 'api.wayknack.com';

// again, in dev mode this will be handled by create-react-app's proxy
// FIXME: Setup nginx and use HTTPS in production
export const PROTOCOL = IS_DEVELOPMENT ? '' : 'https://';

// Unfortunately create-react-app doesn't proxy websockets, so we need to
// specify the full URL here
export const WS_URL = IS_DEVELOPMENT
  ? 'ws://localhost:49808/chat'
  : `wss://websocket.wayknack.com/chat`;

console.log(`WS_URL: ${WS_URL}`);
console.log(`BASE_URL: ${BASE_URL}`);
export const NO_OP = () => {};

// it's actually 64, but we want to leave some space for padding
export const FOOTER_HEIGHT = 70;
