import dayjs from 'dayjs';

const validatePhoneNumber = (input) => {
  const digitInput = input.replace(/\D/g, '');
  if (digitInput.length === 10) {
    return digitInput;
  }
};

const getTimeString = (t) => {
  if (!t || t === 'null') {
    return 'TBD';
  }
  const date = new Date(t);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hour = hours % 12 || 12;
  const minute = minutes < 10 ? '0' + minutes : minutes;
  return `${hour}:${minute}${ampm}`;
};

const isInPast = (d) => {
  return d && d < new Date();
};

const getDateString = (d) => {
  if (!d) {
    return 'tbd';
  }
  const date = dayjs(d);
  return date.format('dddd, MMMM D');
};

const getRelativeDateStr = (d) => {
  let dateString;
  if (d < new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)) {
    dateString = d.toLocaleDateString('en-us', {
      month: 'long',
      day: 'numeric',
    });
  } else {
    dateString = d.toLocaleDateString('en-us', {
      weekday: 'long',
    });
  }
  return dateString;
};

const isValidDate = (d) => {
  return isNaN(Date.parse(d)) == false;
};

export {
  isValidDate,
  getTimeString,
  getDateString,
  getRelativeDateStr,
  isInPast,
  validatePhoneNumber,
};
