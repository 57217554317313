import './wdyr'; // <--- first import

import React, { useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomeScreen from './screens/HomeScreen';
import { BottomNav } from './components/BottomNav';
import { Header } from './components/Header';
import InviteScreen from './screens/InviteScreen';
import { NewInviteScreen } from './screens/NewInviteScreen';
import { ConversationScreen } from './screens/Conversation/ConversationScreen';
import AuthedRoute from './components/AuthedRoute';
import { FirebaseSignIn } from './screens/LoginScreen';
import { FinishAccountSetupScreen } from './screens/FinishAccountSetupScreen';
import AuthContextProvider from './contexts/AuthContext';
import InviteProvider from './contexts/InvitesContext';
import ContactsScreen from './screens/ContactsAndStripesScreen/ContactsScreen';
import HeaderProvider from './contexts/HeaderContext';

const pathsWithBottomNav = ['/', '/new', '/stripes'];
export const showFooter = (path) => {
  return path !== '/login' && pathsWithBottomNav.indexOf(path) !== -1;
};

window.hasCheckedPush = false;

function App() {
  return (
    <HeaderProvider>
      <AuthContextProvider>
        <InviteProvider>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route
                path='/'
                element={
                  <AuthedRoute>
                    <HomeScreen />
                  </AuthedRoute>
                }
              />
              <Route
                path='new'
                element={
                  <AuthedRoute>
                    <NewInviteScreen />
                  </AuthedRoute>
                }
              />
              <Route
                path='i/:inviteId'
                element={
                  <AuthedRoute>
                    <ConversationScreen />
                  </AuthedRoute>
                }
              />
              <Route path='invites/join/:shareId' element={<InviteScreen />} />
              <Route
                path='finishSetup/:shareId?'
                element={<FinishAccountSetupScreen />}
              />
              <Route path='login/:shareId?' element={<FirebaseSignIn />} />

              <Route path='contacts' element={<ContactsScreen />} />
              {/* A route that captures stripe/stripe_id */}
              <Route
                path='stripes/:stripeId'
                element={
                  <AuthedRoute>
                    <HomeScreen />
                  </AuthedRoute>
                }
              />
              <Route path='*' element={<h1>Route does not exist</h1>} />
            </Routes>
            <BottomNav />
          </BrowserRouter>
        </InviteProvider>
      </AuthContextProvider>
    </HeaderProvider>
  );
}

export default App;
