// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAC_YXIJ6pwd0pWxMIWBGp_2bdIFmcYVrM',
  authDomain: 'confirm-your-herd.firebaseapp.com',
  projectId: 'confirm-your-herd',
  storageBucket: 'confirm-your-herd.appspot.com',
  messagingSenderId: '488875641689',
  appId: '1:488875641689:web:a4d221bf75e2d867c45a48',
  measurementId: 'G-VS26WYBER3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
