import React, { useState, useContext } from 'react';
import { authContext } from '../../contexts/AuthContext';
import {
  ActivityTitleRow,
  DateTimeRow,
  LocationRow,
  RespondToInviteRow,
} from '../HomeScreen';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { useNetworkManager } from '../../util/Network';

const ConversationHeader = ({
  invite,
  refreshInvite,
  setEditModalOpen,
  setConfirmDeleteAlertOpen,
  setConfirmDeclineAlertOpen,
}) => {
  const { user } = useContext(authContext);
  const { makeGetRequest } = useNetworkManager();
  const isOwner = user.user_id === invite.author_id;

  const [expanded, setExpanded] = useState(false);

  const createShareLink = async () => {
    console.log('creating share link: ', invite);
    const { response, error } = await makeGetRequest(
      `invites/share/${invite.invite_id}`
    );
    console.log('result', response);
    if (response) {
      const { shareId } = response;
      const shareUrl = `${window.location.origin}/invites/join/${shareId}`;
      const shareText = `Join my event! ${shareUrl}`;
      if (navigator.share) {
        navigator.share({
          title: `Join ${invite.name}!`,
          text: shareText,
          url: shareUrl,
        });
      } else {
        // Fallback for browsers that don't support Web Share API
        const shareInput = document.createElement('input');
        shareInput.value = shareUrl;
        document.body.appendChild(shareInput);
        shareInput.select();
        document.execCommand('copy');
        document.body.removeChild(shareInput);
        alert('Link copied to clipboard');
      }
    }
  };

  if (!invite) {
    return null;
  }

  return (
    <section
      role='button'
      onClick={() => {
        setExpanded((prev) => {
          return !prev;
        });
      }}
      className={
        'bg-zinc-100 sticky top-[67px] text-sm p-5 z-50 ' +
        (invite.cancelled_at_timestamp && 'text-gray-400')
      }
    >
      <div className='bg-white p-3 rounded-xl'>
        <div className='flex width-full justify-between items-center '>
          <ActivityTitleRow invite={invite} />
          <div className='flex items-start w-full flex-1'>
            {isOwner && (
              <button
                onClick={() => {
                  setEditModalOpen(true);
                }}
                className='ml-3 mr-3'
              >
                <PencilIcon className='h-4 w-4' />
              </button>
            )}
          </div>
          <div />
          {expanded ? (
            <ChevronUpIcon className='h-5 w-5 mx-2' />
          ) : (
            <ChevronDownIcon role='presentation' className='h-5 w-5 mx-2' />
          )}
        </div>
        <DateTimeRow invite={invite} />
        <LocationRow invite={invite} />

        <Transition
          // enter="transition duration-200 ease-in"
          // enterFrom="transform scale-95 opacity-0"
          // enterTo="transform scale-95 opacity-100"
          // leave="transition duration-100 ease-out"
          // leaveFrom="transform scale-100 opacity-100"
          // leaveTo="transform scale-95 opacity-0"
          show={expanded}
        >
          <div
            className={`mt-2 pt-2 ${expanded ? 'block' : 'hidden'}
      `}
          >
            <div
              id='zebra-share'
              className='flex h-full items-center justify-center'
            >
              <button type='button' onClick={() => createShareLink()}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z'
                  />
                </svg>
              </button>
            </div>
            {!isOwner && (
              <RespondToInviteRow
                invite={invite}
                refreshInvite={refreshInvite}
                onDeclineOverride={() => {
                  setConfirmDeclineAlertOpen(true);
                }}
              />
            )}
          </div>
          <div className='flex flex-col items-start text-xs'>
            <div className='mt-2 flex items-center'>
              {isOwner && (
                <>
                  <CheckCircleIcon className='h-5 w-5 bg-green-50 rounded-full mr-1.5 text-green-700' />{' '}
                  Me (Organizer)
                </>
              )}
            </div>
            {invite.participants
              .filter((p) => p)
              .map((participant) => {
                // Hide author name from list of atendees
                // if (participant?.user_id === invite.author_id) {
                //   return null;
                // }
                console.log('participant header', participant);
                return (
                  <div key={participant.user_id} className='flex items-center'>
                    {participant.status === 'ACCEPTED' ? (
                      <CheckCircleIcon className='h-5 w-5 bg-green-50 rounded-full mr-1.5 text-green-700' />
                    ) : participant.status === 'DECLINED' ? (
                      <XCircleIcon className='h-5 w-5 bg-red-50 rounded-full mr-1.5 text-red-700' />
                    ) : (
                      <QuestionMarkCircleIcon className='h-5 w-5 bg-yellow-50 p-.5 rounded-full mr-1 text-yellow-700' />
                    )}
                    {participant.name}{' '}
                    {participant.user_id === invite.author_id && '(Organizer)'}
                  </div>
                );
              })}
          </div>
        </Transition>
      </div>
    </section>
  );
};

export { ConversationHeader };
