import * as React from 'react';

export const headerContext = React.createContext();
const { Provider } = headerContext;

const HeaderProvider = ({ children }) => {
  const { backButtonFn, setBackButtonFn } = useHeader();
  return (
    <Provider value={{ backButtonFn, setBackButtonFn }}>{children}</Provider>
  );
};

export const useHeader = () => {
  const [backButtonFn, setBackButtonStateFn] = React.useState(null);
  const setBackButtonFn = (fn) => {
    // We have to use the functional form of setState here because we are
    // setting the state _to_ a function, not _with_ a function.
    setBackButtonStateFn((_) => fn);
  };

  return { backButtonFn, setBackButtonFn };
};

export default HeaderProvider;
