import React, { Fragment, useEffect, useState } from 'react';

import Datepicker from 'react-tailwindcss-datepicker';

import dayjs from 'dayjs';
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  RocketLaunchIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { BOTTOM_NAV_Z_INDEX } from './BottomNav';
import { Listbox } from '@headlessui/react';

const ActivityInput = ({ placeholder, value, onChange }) => {
  return (
    <div className='flex flex-col gap-y-1'>
      <FormLabel Icon={RocketLaunchIcon} label={'Activity'} />
      <input
        type='text'
        value={value}
        onChange={onChange}
        placeholder={placeholder || 'Activity'}
        className='relative transition-all duration-300 w-full border-gray-300 rounded-lg tracking-wide placeholder:font-light placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-orange-500 focus:ring-orange-500/20 pl-4 pr-14 py-5'
      />
    </div>
  );
};

const LocationInput = ({ placeholder, value, onChange }) => {
  return (
    <div className='flex flex-col gap-y-1'>
      <FormLabel Icon={MapPinIcon} label={'Location'} />
      <input
        value={value}
        type='text'
        onChange={onChange}
        placeholder={placeholder || 'Location'}
        className='relative transition-all duration-300 w-full border-gray-300 rounded-lg tracking-wide placeholder:font-light placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-orange-500 focus:ring-orange-500/20 pl-4 pr-14 py-5'
      />
    </div>
  );
};

const DateInput = ({ value, onChange, ...rest }) => {
  // https://day.js.org/docs/en/display/format
  const displayFormat = 'dddd, MMMM D';

  // https://react-tailwindcss-datepicker.vercel.app/props
  // this component can also handle date ranges, if we ever want to
  // implement end dates
  return (
    <div className='flex flex-col gap-y-1'>
      <FormLabel Icon={CalendarIcon} label={'Date'} />
      <Datepicker
        useRange={false}
        asSingle={true}
        value={value}
        dateLooking='forward'
        onChange={onChange}
        displayFormat={displayFormat}
        placeholder='Date'
        primaryColor={'orange'}
        minDate={dayjs()}
        inputClassName={
          'relative transition-all duration-300 w-full border-gray-300 rounded-lg tracking-wide placeholder:font-light placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-orange-500 focus:ring-orange-500/20 pl-4 pr-14 py-5'
        }
        containerClassName={`z-[${BOTTOM_NAV_Z_INDEX + 1}] relative w-full text-base`}
        {...rest}
      />
    </div>
  );
};
const timeIncrements = [
  { value: '0', label: '00' },
  { value: '5', label: '05' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
  { value: '55', label: '55' },
];

export const FormLabel = ({ Icon, label }) => {
  return (
    <p className='flex items-center text-xs text-gray-600'>
      <Icon className='w-5 h-5 mr-1' /> {label || 'Time'}
    </p>
  );
};
const BlankOption = () => {
  return <option value='--'>--</option>;
};

const getISOStringFromStatePieces = ({ hour, min, ampm }) => {
  return dayjs(`${hour}:${min}${ampm}`, 'h:mma').toISOString();
};

const getInitialStateFromValue = (value) => {
  if (!dayjs(value).isValid()) {
    return { initialHour: '', initialMin: '', initialAMPM: '' };
  }
  const initialTime = new Date(value);

  const initialHour = initialTime.getHours() % 12 || 12;
  const initialAMPM = initialTime.getHours() >= 12 ? 'pm' : 'am';

  const initialMin = initialTime.getMinutes();

  return { initialHour, initialMin, initialAMPM };
};
const TimeInput = ({ label, value, onChange, forwardRef, ...rest }) => {
  // value will be the output of toISOString
  // get min, hour, and ampm from value

  const { initialHour, initialMin, initialAMPM } =
    getInitialStateFromValue(value);

  const [min, setMin] = useState(initialMin);
  const [hour, setHour] = useState(initialHour);
  const [ampm, setAmpm] = useState(initialAMPM);

  useEffect(() => {
    if ((min === 0 || min) && (hour === 0 || hour) && ampm) {
      onChange(getISOStringFromStatePieces({ hour, min, ampm }));
    } else if (!min && !hour && !ampm) {
      onChange(null);
    } else {
      onChange('invalid');
    }
  }, [min, hour, ampm]);

  const showClearButton = min !== '' || hour !== '' || ampm !== '';

  return (
    <div className='flex-col text-base'>
      <FormLabel Icon={ClockIcon} label={label} />
      <div
        role='input'
        className='flex w-full justify-center mt-2 px-1 bg-white rounded-lg py-4'
      >
        <div className='flex justify-center items-center gap-x-2 text-right'>
          <select
            name='hours'
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            className='bg-transparent  appearance-none outline-non text-center'
          >
            {hour === '' && <BlankOption />}
            {[...Array(12).keys()].map((hour) => {
              return (
                <option key={hour + 1} value={hour + 1}>
                  {hour + 1}
                </option>
              );
            })}
          </select>
          <span className=''>:</span>
          <select
            name='minutes'
            value={min}
            onChange={(e) => setMin(e.target.value)}
            className='bg-transparent appearance-none outline-none'
          >
            {min === '' && <BlankOption />}
            {timeIncrements.map((time) => {
              return (
                <option key={time.value} value={time.value}>
                  {time.label}
                </option>
              );
            })}
          </select>
          <select
            name='ampm'
            value={ampm}
            onChange={(e) => setAmpm(e.target.value)}
            className='bg-transparent appearance-none outline-none'
          >
            {ampm === '' && <BlankOption />}
            <option value='am'>AM</option>
            <option value='pm'>PM</option>
          </select>

          <XMarkIcon
            onClick={() => {
              setMin('');
              setHour('');
              setAmpm('');
            }}
            className={`h-4 w-4 ${showClearButton ? 'block' : 'invisible'}`}
          />
        </div>
      </div>
      {/* <button className="bg-orange-500 text-white rounded-lg p-2 mt-2">Clear</button> */}
    </div>
  );
};

const people = [
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
  { id: 1, name: 'Durward Reynolds' },
  { id: 2, name: 'Kenton Towne' },
  { id: 3, name: 'Therese Wunsch' },
  { id: 4, name: 'Benedict Kessler' },
  { id: 5, name: 'Katelyn Rohan' },
];

const deriveStripesFromContacts = ({ stripes, participants }) => {
  const participantIds = participants.map(
    (participant) => participant.contact_id
  );
  const stripesToReturn = [];
  for (let i = 0; i < stripes.length; i++) {
    let allParticipantsInStripe = false;

    const stripe = stripes[i];
    const contactIdsInStripe = stripe.contact_ids;
    if (participants.length > 0) {
      allParticipantsInStripe = contactIdsInStripe.every((contactId) => {
        return participantIds.includes(contactId);
      });
    }
    if (allParticipantsInStripe) {
      stripesToReturn.push(stripe);
    }
  }
  return stripesToReturn;
};

function ParticipantsInput({ stripes, contacts, participants = [], onChange }) {
  const selectedContacts = participants;
  const selectedStripes = selectedContacts
    ? deriveStripesFromContacts({
        stripes: stripes,
        participants: participants,
      })
    : [];

  return (
    <div className='w-full'>
      <FormLabel Icon={MapPinIcon} label={'Participants'} />
      <Listbox
        as='div'
        className='p-3 rounded-lg relative bg-white'
        value={[...selectedContacts, ...selectedStripes]}
        placeholder='Select participants'
        onChange={(newValues) => {
          const newSelectedContacts = newValues.filter((obj) => obj.contact_id);
          if (newSelectedContacts.length !== selectedContacts.length) {
            // they tapped to add or remove a contact
            // determine if the stripes need to change, too
            onChange(newSelectedContacts);
          } else {
            const newSelectedStripes = newValues.filter((obj) => obj.stripe_id);
            if (newSelectedStripes.length > selectedStripes.length) {
              const clickedStripe = newSelectedStripes.filter((stripe) => {
                return !selectedStripes.includes(stripe);
              })[0];
              const contactsInStripe = clickedStripe.contact_ids;

              const newParticipants = [];
              for (let i = 0; i < contactsInStripe.length; i++) {
                const contactId = contactsInStripe[i];
                const contact = contacts.find(
                  (contact) => contact.contact_id === contactId
                );
                newParticipants.push(contact);
              }

              for (let i = 0; i < selectedContacts.length; i++) {
                const contact = selectedContacts[i];
                if (!contactsInStripe.includes(contact.contact_id)) {
                  newParticipants.push(contact);
                }
              }
              onChange(newParticipants);
            } else {
              const clickedStripe = selectedStripes.filter((stripe) => {
                return !newSelectedStripes.includes(stripe);
              })[0];
              const contactsInStripe = clickedStripe.contact_ids;

              const newParticipants = newSelectedContacts.filter((contact) => {
                return !contactsInStripe.includes(contact.contact_id);
              });
              onChange(newParticipants);
            }
          }
        }}
        multiple
      >
        <Listbox.Button className='w-full h-full min-h-[40px]'>
          <div className='bg-white w-full h-full flex flex-wrap gap-1'>
            {selectedContacts
              .filter((obj) => !obj.stripe_id)
              .map((obj) => (
                <div
                  key={obj.name}
                  className='text-xs font-semibold bg-zebraTheme text-white border rounded-2xl p-3'
                >
                  {obj.name}
                </div>
              ))}
          </div>
        </Listbox.Button>
        <Listbox.Options className='absolute top-0 left-0 w-full overflow-y-scroll max-h-[50vh] bg-white z-[99999] shadow-md border border-gray-100'>
          <div className='w-full pt-2'>
            <StripesInner stripes={stripes} />
            <ContactsInner contacts={contacts} />
          </div>
        </Listbox.Options>
      </Listbox>
    </div>
  );
}

const DividerText = ({ label }) => {
  return (
    <p
      style={{
        lineHeight: '0.1em',
      }}
      className='my-3 w-full text-left pl-8 border-b border-orange-600 font-semibold text-sm'
    >
      <span className='bg-white px-3'>{label}</span>
    </p>
  );
};
const StripesInner = ({ stripes }) => {
  if (!stripes) {
    return null;
  }

  return (
    <>
      <DividerText label='Stripes' />
      {stripes.map((stripe) => {
        return (
          <Listbox.Option key={stripe.stripe_id} value={stripe} as={Fragment}>
            {({ active, selected }) => (
              <li
                className={`flex p-4 text-sm ${
                  selected
                    ? 'bg-zebraTheme-light font-semibold'
                    : 'bg-white text-black'
                }`}
              >
                {stripe.name}
              </li>
            )}
          </Listbox.Option>
        );
      })}
    </>
  );
};

const ContactsInner = ({ contacts }) => {
  if (!contacts) {
    return null;
  }
  return (
    <>
      <DividerText label='Contacts' />
      {contacts.map((contact) => {
        return (
          <Listbox.Option key={contact.name} value={contact} as={Fragment}>
            {({ active, selected }) => {
              return (
                <li
                  className={`flex p-4 text-sm ${
                    selected
                      ? 'bg-zebraTheme-light font-semibold'
                      : 'bg-white text-black'
                  }`}
                >
                  {contact.name}
                </li>
              );
            }}
          </Listbox.Option>
        );
      })}
    </>
  );
};

export {
  ActivityInput,
  LocationInput,
  DateInput,
  TimeInput,
  ParticipantsInput,
};

export const getTimeIncrements = (numIncrements = 30) => {
  const options = [];
  for (let i = 0; i < (60 / numIncrements) * 24; i++) {
    options.push(
      dayjs()
        .startOf('day')
        .add(numIncrements * i, 'minute')
    );
  }
  return options;
};

export const convertHourStringToTime = (hourString) => {};
