import { onAuthStateChanged } from 'firebase/auth';
import * as React from 'react';
import { auth as firebaseAuth } from '../firebase';

export const authContext = React.createContext();
const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const {
    firebaseUser,
    setFirebaseUser,
    user,
    token,
    isAuthed,
    setAuth,
    clearAuth,
  } = useAuth();
  return (
    <Provider
      value={{
        firebaseUser,
        setFirebaseUser,
        user,
        token,
        isAuthed,
        setAuth,
        clearAuth,
      }}
    >
      {children}
    </Provider>
  );
};

const getDefaultFromLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      const obj = JSON.parse(value);
      return obj;
    } catch (e) {
      console.log(`Error parsing ${key} from local storage`, e);
      return value;
    }
  }
  return '';
};

export const useAuth = () => {
  const [firebaseUser, setFirebaseUser] = React.useState(
    getDefaultFromLocalStorage('firebaseUser')
  );
  const [user, setUser] = React.useState(getDefaultFromLocalStorage('user'));
  const [token, setToken] = React.useState(getDefaultFromLocalStorage('token'));

  React.useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', JSON.stringify(token));
    } else {
      window.localStorage.removeItem('token');
    }

    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user));
    } else {
      window.localStorage.removeItem('user');
    }

    if (firebaseUser) {
      window.localStorage.setItem('firebaseUser', JSON.stringify(firebaseUser));
    } else {
      window.localStorage.removeItem('firebaseUser');
    }
  }, [token, user, firebaseUser]);

  React.useEffect(() => {
    onAuthStateChanged(firebaseAuth, (userFromFirebase) => {
      if (userFromFirebase) {
        setFirebaseUser(userFromFirebase);
        setToken(userFromFirebase.accessToken);
      } else {
        setFirebaseUser(false);
        setToken(null);
      }
    });
  }, []);

  const clearAuth = () => {
    setFirebaseUser(null);
    setToken(null);
    firebaseAuth.signOut();
    setUser(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('firebaseUser');
  };

  const setAuth = (user, token) => {
    setUser(user);
    setToken(token);
  };

  const isAuthed = () => {
    return !!user && !!firebaseUser && !!token;
  };

  return {
    firebaseUser,
    setFirebaseUser,
    user,
    token,
    setAuth,
    isAuthed,
    clearAuth,
  };
};

export default AuthProvider;
