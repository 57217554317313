import React from 'react';

import { ButtonsToDownloadApp } from '../../components/ButtonsToDownloadApp';

import { isOnWeb, sendNativeMessage } from '../../util/NativeBridge';
import { ChevronRightIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';

export const ContactsView = ({
  hasGrantedContactsPermission,
  contacts,
  onContactClick,
}) => {
  if (hasGrantedContactsPermission === false) {
    const goToSettings = async () => {
      const goToSettingsMessage = {
        namespace: 'contacts',
        action: 'openSettings',
        parameters: {},
      };
      sendNativeMessage(goToSettingsMessage);
    };

    return (
      <div className='flex flex-col items-center  bg-white rounded-md h-full m-4 p-3 text-center'>
        <p className='text-md'>Please allow Zebra to access your contacts</p>
        <button
          onClick={goToSettings}
          className='flex flex-row gap-x-2 align-middle items-center mt-2 bg-blue-600 text-white p-2 rounded-md text-sm'
        >
          <Cog8ToothIcon className='h-5 w-5' />
          Go to Settings
        </button>
      </div>
    );
  }

  if (!contacts || (!isOnWeb() && hasGrantedContactsPermission === undefined)) {
    return <ContactLoadingView />;
  }

  if (contacts.length === 0) {
    // User has no contacts. This almost certainly means they're on the web.
    return isOnWeb() ? (
      <div className='flex flex-col items-center  bg-white rounded-md h-full m-4 p-3 text-center'>
        <ButtonsToDownloadApp ctaText={'Download the app to find your herd!'} />
      </div>
    ) : (
      // FIXME: Make this nicer. We shouldn't hit this while users are invite only
      <>No Contacts Found</>
    );
  }

  // an object in the shape of { A: [contact1, contact2], B: [contact3, contact4] }
  const lettersToContacts = {};
  contacts.forEach((contact) => {
    const firstLetter = contact.name[0].toUpperCase();
    if (!lettersToContacts[firstLetter]) {
      lettersToContacts[firstLetter] = [contact];
    } else {
      lettersToContacts[firstLetter].push(contact);
    }
  });

  return (
    <>
      <div className='bg-white rounded-md h-full m-4 p-3'>
        {/* <div
          className='flex justify-between py-3 text-sm border-b-2'
          onClick={() => {
            console.log('open find / invite friends');
          }}
        >
          Find & Invite Friends
          <ChevronRightIcon className='h-5 w-5' />
        </div> */}

        {Object.keys(lettersToContacts).map((letter, outerIndex) => {
          const isLastLetter =
            outerIndex === Object.keys(lettersToContacts).length - 1;
          return (
            <div key={letter}>
              <div className='font-semibold  text-gray-400 text-base my-4'>
                {letter}
              </div>
              {lettersToContacts[letter].map((contact, index) => {
                const isLastItemInList =
                  index === lettersToContacts[letter].length - 1;
                return (
                  <div
                    key={contact.contact_id + ' ' + index}
                    onClick={() => {
                      onContactClick(contact);
                    }}
                    className={`flex flex-row justify-around items-center py-3 text-sm border-t-2 ${isLastItemInList && !isLastLetter && 'border-b-2'} ${index === 0 && 'border-t-2'} `}
                  >
                    <div className='flex-1'>{contact.name}</div>
                    <button
                      className={`flex flex-row align-middle items-center bg-white text-blue-700 border rounded-md p-2 font-semibold border-blue-700 ${contact.contact_id && 'invisible'}`}
                    >
                      Invite
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContactLoadingView = ({}) => {
  const alphabet = 'ABCDEFGH'.split('');
  return (
    <div className='bg-white rounded-md h-full m-4 p-3'>
      {alphabet.map((letter, i) => {
        return (
          <div key={letter}>
            <div className='font-semibold  text-gray-400 text-base my-4'>
              {letter}
            </div>
            <ContactSkeletonRow />
            <ContactSkeletonRow />
            <ContactSkeletonRow />
          </div>
        );
      })}
    </div>
  );
};

const ContactSkeletonRow = () => {
  return (
    <div className='flex animate-pulse flex-row items-center py-5 text-sm font-medium border-t-2'>
      <div className='w-2/3 bg-gray-300 h-6 rounded-md '></div>
    </div>
  );
};
