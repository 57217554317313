import React from 'react';
import { ArrowLeftIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { authContext } from '../contexts/AuthContext';
import IconDropDownMenu from './IconDropDownMenu';
import { useNavigate } from 'react-router-dom';
import { headerContext } from '../contexts/HeaderContext';

const APP_BAR_ID = 'zebra-app-bar';
export const getHeaderHeight = () => {
  return document.getElementById(APP_BAR_ID)?.offsetHeight || 56;
};

const BackButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className='bg-zebraTheme-light  text-zebraTheme '>
      <ArrowLeftIcon className='rounded-full w-8' />
    </button>
  );
};

const FeedbackButton = () => {
  return (
    <div className='flex items-center w-[32px]'>
      <IconDropDownMenu
        icon={<SmartToy className='w-10 h-10 text-zebraTheme' fill='none' />}
        direction='right'
        options={[
          {
            label: 'About Us',
            onClick: () => {},
          },
          {
            label: 'Give Feedback',
            onClick: () => {},
          },
        ]}
      />
    </div>
  );
};

function Header() {
  const { isAuthed, clearAuth } = React.useContext(authContext);
  const navigate = useNavigate();
  const { backButtonFn } = React.useContext(headerContext);

  return (
    <>
      <div
        style={{ zIndex: 99999 }}
        className='py-2 flex justify-between w-full bg-zebraTheme-light sticky top-0 px-4 '
      >
        <div className='flex w-[32px]'>
          {backButtonFn ? (
            <BackButton onClick={backButtonFn} />
          ) : (
            <FeedbackButton />
          )}
        </div>

        <h1
          style={{
            fontFamily: 'Ruda, sans-serif',
          }}
          className='text-zebraTheme font-bold text-2xl'
        >
          zebra
        </h1>
        <div className='flex'>
          {isAuthed() && (
            <button className='flex relative items-center bg-zebraTheme-light  text-zebraTheme'>
              <IconDropDownMenu
                icon={<UserCircleIcon className='w-8 h-8 text-zebraTheme' />}
                options={[
                  {
                    label: 'Sign out',
                    onClick: () => {
                      clearAuth();
                      navigate('/login');
                    },
                  },
                ]}
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
export { Header };

const SmartToy = ({ className }) => {
  return (
    <svg fill='#E26E02' height={24} width='24'>
      <g>
        <rect fill='none' height='24' width='24' y='0' />
      </g>
      <g>
        <path d='M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3C23,10.34,21.66,9,20,9z M7.5,11.5C7.5,10.67,8.17,10,9,10 s1.5,0.67,1.5,1.5S9.83,13,9,13S7.5,12.33,7.5,11.5z M16,17H8v-2h8V17z M15,13c-0.83,0-1.5-0.67-1.5-1.5S14.17,10,15,10 s1.5,0.67,1.5,1.5S15.83,13,15,13z' />
      </g>
    </svg>
  );
};
