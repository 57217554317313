const Colors = {
  lightestOrange: '#FFF1E5',
  themePrimary: '#FF9635', // zebra orange
  themeSecondary: '#2A4F79', // dark blue
  themePrimaryContrast: '#000',
  themeSecondaryContrast: '#ffffff',
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  lightestGray: '#ebe8e8',
  lightGray: '#d3d3d3',
  darkGray: '#a9a9a9',
  darkBlue: '#000080',
  darkTeal: '#225560',
  success: '#4BB543',
  acceptedGreen: '#3E7722',
  rejectedRed: '#861313',
  infoBackgroundRed: '#F9DBD2',
  infoTextColorRed: '#E44F24',
};
export { Colors };
