import React, { useState, useContext, useEffect, useMemo } from 'react';
import { InvitesContext, isInviteInPast } from '../contexts/InvitesContext';
import { getDateString, getTimeString } from '../util/Utils';
import { CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { authContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FOOTER_HEIGHT } from '../util/Constants';
import {
  sendNativeMessage,
  subscribeToNativeChannel,
  unsubscribeFromNativeChannel,
} from '../util/NativeBridge';
import { useNetworkManager } from '../util/Network';

const UPCOMING_TAB = 'Upcoming';
const CANCELLED_TAB = 'Past / Cancelled';
export default function HomeScreen() {
  const { makePostRequest } = useNetworkManager();
  const { user } = useContext(authContext);
  const [selectedTab, setSelectedTab] = useState(UPCOMING_TAB);
  const { invites, refreshInvites } = useContext(InvitesContext);
  useEffect(() => {
    refreshInvites();
  }, []);

  useEffect(() => {
    if (!window.hasCheckedPush) {
      sendNativeMessage({
        namespace: 'push',
        action: 'register',
        parameters: { key: 'value' }, // testing
      }).then(async ({ token }) => {
        if (token) {
          await makePostRequest('auth/push_token/register', {
            token,
            userId: user.user_id,
          });
        }
        // We only need to check once per app-startup
        window.hasCheckedPush = true;
      });
    }
  }, []);

  useEffect(() => {
    const subscriptionToken = subscribeToNativeChannel(
      'lifecycle',
      ({ type }) => {
        if (type === 'returningToForeground') {
          // Check for new invites (or badge counts) when we come back from the background
          refreshInvites();
        }
      }
    );

    return () => {
      unsubscribeFromNativeChannel('lifecycle', subscriptionToken);
    };
  }, []);

  const invitesToRender = useMemo(() => {
    if (!invites) {
      return [];
    }
    if (selectedTab === UPCOMING_TAB) {
      return invites.filter((invite) => {
        const notCancelled = invite.cancelled_at_timestamp === null;
        return notCancelled && !isInviteInPast(invite);
      });
    } else {
      return invites.filter((invite) => {
        const cancelled = invite.cancelled_at_timestamp !== null;
        return cancelled || isInviteInPast(invite);
      });
    }
  }, [selectedTab, invites]);

  return (
    <div style={{ marginBottom: FOOTER_HEIGHT }}>
      <div id='home-screen-tabs' className='flex m-2 mt-4 pl-2'>
        <ul className='flex flex-wrap text-sm font-medium text-left gap-4 '>
          <li className='me-2'>
            <button
              onClick={() => {
                setSelectedTab(UPCOMING_TAB);
              }}
              className={`inline-flex  group px-2 ${
                selectedTab === UPCOMING_TAB
                  ? ' text-black border-b-2 border-black '
                  : 'text-gray-500 hover:text-black'
              }`}
            >
              {UPCOMING_TAB}
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                setSelectedTab(CANCELLED_TAB);
              }}
              className={`inline-flex group px-2 ${
                selectedTab === CANCELLED_TAB
                  ? 'text-black  border-b-2 border-black '
                  : 'text-gray-500  hover:text-black'
              }`}
              aria-current='page'
            >
              {CANCELLED_TAB}
            </button>
          </li>
        </ul>
      </div>
      <div
        id='home-screen-body'
        className={
          'flex flex-col mx-8 ' +
          (selectedTab === 'Past / Cancelled' && ' text-gray-400 ')
        }
      >
        {invitesToRender?.map((invite) => {
          return <InviteCard key={invite.invite_id} invite={invite} />;
        })}
      </div>
    </div>
  );
}

const InviteCard = ({ invite }) => {
  const { user } = useContext(authContext);
  const isAuthor = invite.author?.user_id === user?.user_id;
  const navigate = useNavigate();

  return (
    <div
      role='button'
      onClick={(e) => {
        navigate(`/i/${invite.invite_id}`);
      }}
      className='flex-col bg-white mt-4 p-4 rounded-xl grid space-y-1.5 relative'
    >
      <ActivityInfoBadge invite={invite} />
      <ActivityTitleRow invite={invite} />
      <DateTimeRow invite={invite} />
      <LocationRow invite={invite} />
      <div className='text-xs'>
        Organized by {isAuthor ? 'Me' : invite.author?.name}
      </div>
      {!isAuthor && <RespondToInviteRow invite={invite} />}
    </div>
  );
};

export const ActivityInfoBadge = ({ invite }) => {
  const hasUnseenEventCreation = invite.has_unseen_event_creation;
  const hasUnseenEventEdits = invite.has_unseen_event_edits;
  const hasUnseenEventCancellations = invite.has_unseen_event_cancellations;
  const unreadMessagesCount = invite.unread_messages_count;

  let str = '';
  if (hasUnseenEventCancellations) {
    str = 'Cancelled';
  } else if (hasUnseenEventCreation) {
    str = 'New';
  } else if (hasUnseenEventEdits) {
    str = 'Updated';
  } else if (unreadMessagesCount > 0) {
    str = unreadMessagesCount;
  }
  if (!str) {
    return null;
  }

  return (
    <div className='absolute right-5 top-3 flex justify-end'>
      <div className='flex items-center justify-center'>
        <div className='bg-zebraTheme-light text-zebraTheme text-xs rounded-md p-1 px-4 font-bold'>
          {str}
        </div>
      </div>
    </div>
  );
};

export const DateTimeRow = ({ invite }) => {
  if (!invite.start_date && !invite.start_time && !invite.end_time) {
    return null;
  }

  return (
    <div className='flex justify-start items-center text-xs'>
      <CalendarIcon className='h-5 w-5 mr-1' />
      <p>
        {getDateString(invite.start_date)},{' '}
        <span className='ml-2'>
          {getTimeString(invite.start_time)} - {getTimeString(invite.end_time)}
        </span>
      </p>
    </div>
  );
};

export const LocationRow = ({ invite }) => {
  if (!invite.location) {
    return null;
  }

  return (
    <div className='flex justify-start items-center text-xs'>
      <MapPinIcon className='h-5 w-5 mr-1' />
      {invite.location}
    </div>
  );
};

export const ActivityTitleRow = ({ invite }) => {
  return (
    <div className='font-semibold text-sm flex items-center justify-evenly'>
      <div className='flex-1'>{invite.activity}</div>
      {invite.cancelled_at_timestamp && (
        <div className='bg-red-100 p-2 text-red-600 text-xs font-semibold rounded-lg'>
          Cancelled
        </div>
      )}
    </div>
  );
};

export const RespondToInviteRow = ({
  invite,
  onDeclineOverride,
  refreshInvite,
}) => {
  const { acceptInvite, declineInvite } = useContext(InvitesContext);
  const { user } = useContext(authContext);

  if (isInviteInPast(invite) || invite.cancelled_at_timestamp) {
    return null;
  }

  const participantRecord = invite.participants?.find(
    (p) => p?.user_id === user?.user_id
  );

  const isPending = !participantRecord?.status;
  console.log('participantRecord', participantRecord);
  console.log('participants', invite.participants);
  console.log('user', user);
  console.log('isPending', isPending);
  console.log('invite', invite);
  const isConfirmed = participantRecord?.status === 'ACCEPTED';
  const isDeclined = participantRecord?.status === 'DECLINED';

  return (
    <div className='grid grid-flow-col space-x-8 items-center text-xs font-semibold'>
      <button
        onClick={(e) => {
          e.stopPropagation();
          acceptInvite({ inviteId: invite.invite_id, userId: user.user_id });
          if (refreshInvite) {
            refreshInvite();
          }
        }}
        disabled={isConfirmed}
        className={
          'border rounded-md p-2 font-semibold border-blue-700 ' +
          getAcceptButtonStyle(participantRecord?.status)
        }
      >
        Accept
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (onDeclineOverride) {
            onDeclineOverride();
          } else {
            declineInvite({ inviteId: invite.invite_id, userId: user.user_id });
            if (refreshInvite) {
              refreshInvite();
            }
          }
        }}
        disabled={isDeclined}
        className={
          'border rounded-md p-2 ' +
          getDeclineButtonStyle(participantRecord?.status)
        }
      >
        Decline
      </button>
    </div>
  );
};

const getAcceptButtonStyle = (status) => {
  console.log('status', status);
  switch (status) {
    case 'DECLINED':
      return ' bg-white text-blue-700 border-blue-700 border opacity-50';
    case 'ACCEPTED':
      return ' bg-blue-100 text-blue-700 border-blue-700 border font-bold';
    default:
      return ' bg-white text-blue-700 border-blue-700';
  }
};

const getDeclineButtonStyle = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return ' bg-white text-blue-700 border-blue-700 border opacity-50';
    case 'DECLINED':
      return ' bg-blue-100 text-blue-700 border-blue-700 border font-bold';
    default:
      return ' bg-white text-blue-700 border-blue-700';
  }
};

const getMaybeButtonStyle = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return ' bg-white text-gray-700 border-gray-700 opacity-50';
    case 'DECLINED':
      return ' bg-white text-gray-700 border-gray-700 opacity-50';
    default:
      return ' bg-white text-gray-700 border-gray-700';
  }
};
