import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useNetworkManager } from '../../util/Network';
import { authContext } from '../../contexts/AuthContext';
import { InvitesContext } from '../../contexts/InvitesContext';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { FOOTER_HEIGHT } from '../../util/Constants';
import { headerContext } from '../../contexts/HeaderContext';
import { PlusIcon } from '@heroicons/react/24/solid';
import { sendNativeMessage } from '../../util/NativeBridge';
import { ContactsView } from './ContactsView';
import { MutualsView } from './MutualsView';
const STRIPES_TAB = 'Stripes';
const CONTACTS_TAB = 'Contacts';
const MUTUALS_TAB = 'Mutuals';

const IS_DEBUGGING_WEB = false;
const DUMMY_CONTACTS = [
  { name: 'Kate Bell', phoneNumbers: ['4155553695'] },
  { name: 'Daniel Higgins', phoneNumbers: ['5554787672'] },
  { name: 'John Appleseed', phoneNumbers: ['8885551212'] },
  { name: 'Anna Haro', phoneNumbers: ['5555228243'] },
  { name: 'Hank Zakroff', phoneNumbers: ['5557664823', '7075551854'] },
  { name: 'David Taylor', phoneNumbers: ['5556106679'] },
  { name: 'Brandon Vongsawad', phoneNumbers: ['7604158004'] },
];

export default function ContactsScreen({}) {
  const { makeGetRequest } = useNetworkManager();
  const [phoneContacts, setPhoneContacts] = useState(undefined);
  const [zebraContacts, setZebraContacts] = useState();
  const [mutualContacts, setMutualContacts] = useState(null); // FIXME: Temp for developing
  const [stripes, setStripes] = useState();
  const [selectedStripe, setSelectedStripe] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [selectedTab, setSelectedTab] = useState('');
  const { setBackButtonFn } = useContext(headerContext);
  const { user } = useContext(authContext);
  const { invites } = useContext(InvitesContext);
  const [hasContactPermission, setContactPermission] = useState(
    undefined // isOnWeb() ? false : undefined
  );

  useEffect(() => {
    return () => {
      setBackButtonFn(null);
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const { response, error } = await makeGetRequest(`contacts/list`);

      const zebraContacts = response;
      if (zebraContacts) {
        console.log(`zebraContacts`, zebraContacts);
        setZebraContacts(zebraContacts);
      } else {
        console.error('Error fetching contacts', error);
        return;
      }

      const getPermissionMessage = {
        namespace: 'contacts',
        action: 'getPermission',
        parameters: {},
      };
      const { hasAccess } = await sendNativeMessage(getPermissionMessage);
      if (IS_DEBUGGING_WEB) {
        // fixme: remove this entire debug block
        setContactPermission(true);
        setPhoneContacts(DUMMY_CONTACTS);
      } else if (hasAccess) {
        setContactPermission(true);
      } else {
        setContactPermission(false);
        setPhoneContacts([]);
        return;
      }
      const getContactsMessage = {
        namespace: 'contacts',
        action: 'getContacts',
        parameters: {},
      };
      let phoneContacts;
      if (IS_DEBUGGING_WEB) {
        phoneContacts = DUMMY_CONTACTS;
      } else {
        const { contacts } = await sendNativeMessage(getContactsMessage);
        phoneContacts = contacts;
      }
      // Extract all the phone numbers we have zebraContacts for
      const zebraPhones = zebraContacts.map((contact) => contact.phone);

      // Filter out phoneContacts whose phoneNumbers include any of the zebraPhones
      phoneContacts = phoneContacts.filter(
        (phoneContact) =>
          !phoneContact.phoneNumbers.some((phoneNumber) =>
            zebraPhones.includes(phoneNumber)
          )
      );
      // sort the contacts by name
      phoneContacts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setPhoneContacts(phoneContacts);
    };
    if (!zebraContacts) {
      loadData();
    }
  }, [zebraContacts, user.user_id]);

  useEffect(() => {
    const fetchStripes = async () => {
      const { response, error } = await makeGetRequest(`stripes/list`);
      if (response) {
        setStripes(response); // FIXME: Temp for developing
        if (response.length === 0) {
          setSelectedTab(STRIPES_TAB);
        } else {
          setSelectedTab(CONTACTS_TAB);
        }
        setSelectedTab(CONTACTS_TAB); // FIXME: Temp for developing
      }
    };
    if (!stripes) {
      fetchStripes();
    }
  }, [stripes, user.user_id]);

  const fetchMutuals = async () => {
    const { response, error } = await makeGetRequest(`contacts/mutuals`);
    console.log('mutuals', response);
    if (response) {
      console.log('mutuals', response);
      setMutualContacts(response.mutuals);
    }
  };
  // Load mutual contacts from GET /contacts/mutuals while passing a list of invite_ids
  useEffect(() => {
    if (mutualContacts === null) {
      fetchMutuals();
    }
  }, [mutualContacts, user.user_id]);

  useEffect(() => {
    if (selectedStripe) {
      setBackButtonFn(() => {
        setSelectedStripe(null);
        setBackButtonFn(null);
      });
    } else {
      setBackButtonFn(null);
    }
  }, [selectedStripe]);

  useEffect(() => {
    if (selectedContact) {
      setBackButtonFn(() => {
        setSelectedContact(null);
        setBackButtonFn(null);
      });
    } else {
      setBackButtonFn(null);
    }
  }, [selectedContact]);

  const contactsToRender = useMemo(() => {
    if (!phoneContacts || !zebraContacts) {
      return undefined;
    }

    // Merge the two lists of contacts; both are alphabetized and in the shape of [{name: 'name', contact_id?: 'id', phoneNumber: 'number'}]
    // Make sure to keep the list alphabetized
    // FIXME: Figure out what to do with duplicate names
    let mergedContacts = [];
    let zebraIndex = 0;
    let phoneIndex = 0;
    while (
      zebraIndex < zebraContacts.length ||
      phoneIndex < phoneContacts.length
    ) {
      const zebraContact = zebraContacts[zebraIndex];
      const phoneContact = phoneContacts[phoneIndex];

      if (!zebraContact) {
        mergedContacts.push(phoneContact);
        phoneIndex++;
        continue;
      }
      if (!phoneContact) {
        mergedContacts.push(zebraContact);
        zebraIndex++;
        continue;
      }
      if (zebraContact.name < phoneContact.name) {
        mergedContacts.push(zebraContact);
        zebraIndex++;
      } else {
        mergedContacts.push(phoneContact);
        phoneIndex++;
      }
    }
    return mergedContacts;
  }, [zebraContacts, phoneContacts]);

  return (
    <div style={{ marginBottom: FOOTER_HEIGHT }} className='mx-3'>
      {selectedStripe ? (
        <StripeDetails stripe={selectedStripe} />
      ) : selectedContact ? (
        <ContactDetails allStripes={stripes} contact={selectedContact} />
      ) : (
        <>
          <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <div>
            {selectedTab === STRIPES_TAB ? (
              <StripesView
                stripes={stripes}
                onStripeClick={setSelectedStripe}
              />
            ) : selectedTab === CONTACTS_TAB ? (
              <>
                <ContactsView
                  hasGrantedContactsPermission={true}
                  contacts={contactsToRender}
                  onContactClick={setSelectedContact}
                />
              </>
            ) : (
              <MutualsView
                mutuals={mutualContacts}
                onContactClick={setSelectedContact}
                onAddMutualSuccess={async () => {
                  await fetchMutuals();
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

const Tabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className='m-2 mt-4 pl-2'>
      <ul className='flex flex-wrap text-sm font-medium text-left gap-4 '>
        <li className='me-2'>
          <button
            onClick={() => {
              setSelectedTab(STRIPES_TAB);
            }}
            className={`inline-flex  group px-2 ${
              selectedTab === STRIPES_TAB
                ? ' text-black border-b-2 border-black '
                : 'text-gray-500 hover:text-black'
            }`}
          >
            {STRIPES_TAB}
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              setSelectedTab(CONTACTS_TAB);
            }}
            className={`inline-flex group px-2 ${
              selectedTab === CONTACTS_TAB
                ? 'text-black  border-b-2 border-black '
                : 'text-gray-500  hover:text-black'
            }`}
            aria-current='page'
          >
            {CONTACTS_TAB}
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              setSelectedTab(MUTUALS_TAB);
            }}
            className={`inline-flex group px-2 ${
              selectedTab === MUTUALS_TAB
                ? 'text-black  border-b-2 border-black '
                : 'text-gray-500  hover:text-black'
            }`}
            aria-current='page'
          >
            {MUTUALS_TAB}
          </button>
        </li>
      </ul>
    </div>
  );
};

const StripesView = ({ stripes, onStripeClick }) => {
  return (
    <div>
      {stripes ? (
        <>
          {stripes.map((stripe) => {
            return (
              <div
                key={stripe.stripe_id}
                className='my-3 p-4 bg-white rounded-lg'
                onClick={() => {
                  onStripeClick(stripe);
                }}
              >
                <div className='text-sm font-semibold'>{stripe.name}</div>
                <PeopleRowInStripesTab stripe={stripe} />
              </div>
            );
          })}
          {/* Make a button that floats in a fixed positino on the bottom right of the screen */}
          <button className='fixed bottom-[88px] right-10 p-3 rounded-full'>
            <PlusIcon className='text-white bg-zebraTheme border-none outline-none rounded-full h-16 w-16 shadow-md' />
          </button>
        </>
      ) : (
        <div>Empty State</div>
      )}
    </div>
  );
};

const StripeDetails = ({ stripe }) => {
  return (
    <div className='text-base flex flex-col gap-y-6 m-4'>
      <div className='bg-white rounded-md h-full p-3'>
        <p className='font-semibold'>{stripe.name}</p>
      </div>
      <div className='bg-white rounded-md h-full px-3 pt-3'>
        <p className='font-semibold text-base'>Contacts</p>
        {stripe.contacts.map((contact) => {
          return (
            <p className='py-3 font-medium my-2 text-sm border-gray-300 [&:not(:last-child)]:border-b'>
              {contact.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const ContactDetails = ({ allStripes, contact }) => {
  return (
    <div className='text-base flex flex-col gap-y-6 m-4'>
      <div className='bg-white rounded-md h-full p-3'>
        <p className='font-semibold'>{contact.name}</p>
      </div>
      <div className='bg-white rounded-md h-full p-3'>
        <p className=' font-semibold mb-2'>Stripes</p>
        {allStripes.map((stripe, index) => {
          const contactIsInStripe = stripe.contacts.some(
            (stripeContact) => stripeContact.contact_id === contact.contact_id
          );
          if (contactIsInStripe) {
            return (
              <div
                key={stripe.stripe_id}
                className='py-3 font-medium my-2 text-sm border-gray-300 [&:not(:last-child)]:border-b'
              >
                <div>{stripe.name}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const PeopleRowInStripesTab = ({ stripe }) => {
  const numPeopleInStripe = stripe.contacts.length;

  if (numPeopleInStripe === 0) {
    return <div>Click to add people to this stripe</div>;
  }

  let peopleString = '';

  if (numPeopleInStripe === 1) {
    peopleString = stripe.contacts[0].name;
  } else if (numPeopleInStripe === 2) {
    peopleString = stripe.contacts
      .map((person) => {
        return getFirstNameAndLastInitial({ name: person.name });
      })
      .join(' and ');
  } else {
    const namesToShow = stripe.contacts.slice(0, 2).map((person) => {
      return getFirstNameAndLastInitial({ name: person.name });
    });
    const leftoverPeople = numPeopleInStripe - namesToShow.length;

    peopleString =
      namesToShow.join(', ') +
      (leftoverPeople
        ? `, + ${numPeopleInStripe - namesToShow.length} other${leftoverPeople > 1 ? 's' : ''}`
        : '');
  }

  return (
    <div className='flex flex-row mt-1'>
      <UserGroupIcon className='h-5 w-5 mr-2' />
      {peopleString}
    </div>
  );
};

const getFirstNameAndLastInitial = ({ name }) => {
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1] ? ` ${nameParts[1][0]}` : '';
  return `${firstName}${lastNameInitial}`;
};

const ExampleSkeleton = () => {
  return (
    <div className='w-60 h-24 border-2 rounded-md mx-auto mt-20'>
      <div className='flex animate-pulse flex-row items-center h-full justify-center space-x-5'>
        <div className='w-12 bg-gray-300 h-12 rounded-full '></div>
        <div className='flex flex-col space-y-3'>
          <div className='w-36 bg-gray-300 h-6 rounded-md '></div>
          <div className='w-24 bg-gray-300 h-6 rounded-md '></div>
        </div>
      </div>
    </div>
  );
};
