// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { Navigate } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';

const AuthedRoute = ({ redirectPath = '/login', children }) => {
  const { isAuthed, user } = React.useContext(authContext);

  // The user needs to finish setting up their account
  if (isAuthed() && !user) {
    return <Navigate to={'/finishSetup'} replace />;
  }

  // They're just not authenticated
  if (!isAuthed()) {
    return <Navigate to={redirectPath} replace />;
  }

  // Good to go!
  return <div>{children}</div>;
};

export default AuthedRoute;
