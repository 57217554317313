let messageIdToPromiseMap = {}; // id: {resolve, reject}
let channelToSubscriberMap = {}; // channelName: [subscriberId: fn]
window.webViewMessageHandler = {
  respond: (id, payload) => {
    messageIdToPromiseMap[id].resolve(payload);
    delete messageIdToPromiseMap[id];
  },
  post: (channel, payload) => {
    const subscribers = channelToSubscriberMap[channel] || [];
    subscribers.forEach((subscriber) => subscriber.fn(payload));
  },
};

let messageCount = 0;
export const sendNativeMessage = ({ namespace, action, parameters }) => {
  return new Promise((resolve, reject) => {
    messageCount += 1;
    const messageId = 'zebra_' + messageCount.toString();
    messageIdToPromiseMap[messageId] = {
      resolve: resolve,
      reject: reject,
    };
    const i = getiOSInterface(namespace, action); // todo: || getAndroidInterface();

    const message = {
      namespace,
      messageId,
      action,
      parameters,
    };
    if (i) {
      i.postMessage(message);
    } else {
      console.log(
        `Cannot send message ${action.toUpperCase()} to ${namespace.toUpperCase()} on this client`
      );
      resolve({});
    }
  });
};

/**
 * subscribe to a native broadcast channel
 * @param {string} channelName - the name of the channel to subscribe to
 * @param {string} fn - the function to run when a message is received
 * @returns {string} subscriberId - the id of the subscriber
 * Be sure to call unsubscribeFromNativeChannel with the id when your component unmounts
 * */
export const subscribeToNativeChannel = (channelName, callback) => {
  const subscriberId = 'zebra_' + Math.random().toString();
  if (!channelToSubscriberMap[channelName]) {
    channelToSubscriberMap[channelName] = [];
  }
  channelToSubscriberMap[channelName].push({ id: subscriberId, fn: callback });
  return subscriberId;
};

/**
 * unsubscribe from a native broadcast channel
 * @param {string} channelName - the name of the channel to unsubscribe from
 * @param {string} subscriberId - the id of the subscriber
 * */
export const unsubscribeFromNativeChannel = (channelName, subscriberId) => {
  const subscribers = channelToSubscriberMap[channelName] || [];
  const subscriberIndex = subscribers.findIndex(
    (subscriber) => subscriber.id === subscriberId
  );
  if (subscriberIndex > -1) {
    subscribers.splice(subscriberIndex, 1);
  }
};

// returns window.webkit.messageHandlers, if it exists
// returns undefined otherwise
const getiOSInterface = (namespace, action) => {
  if (
    window.webkit?.messageHandlers &&
    window.webkit.messageHandlers[namespace]
  ) {
    return window.webkit.messageHandlers[namespace];
  } else {
    return undefined;
  }
};

export const isOnIOS = () => {
  return !!window.webkit;
};

export const isOnWeb = () => {
  // Determine if running on browser
  const isBrowser = typeof window !== 'undefined';
  return !isOnIOS() || isBrowser;
};

// TODO
// const getAndroidInterface = () => {
//   const namespaceKey = "androidBridge_" + namespace;
//   const androidNamespaceHandler = window[namespaceKey];
//   if (androidNamespaceHandler && androidNamespaceHandler.postMessage) {
//     const androidNamespaceHandlerWrapper = {
//       postMessage: (payload) => {
//         // For Android, we must stringify the payload prior to sending.
//         androidNamespaceHandler.postMessage(JSON.stringify(payload));
//       },
//     };
//     return androidNamespaceHandlerWrapper;
//   }
//   return undefined;
// };
