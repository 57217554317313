import React from 'react';
import { EditInviteForm } from '../components/EditInviteForm';
import { useNavigate } from 'react-router-dom';
import { InvitesContext } from '../contexts/InvitesContext';
import { FOOTER_HEIGHT } from '../util/Constants';

function NewInviteScreen() {
  const { addNewInvite } = React.useContext(InvitesContext);
  const navigate = useNavigate();
  return (
    <div
      style={{ marginBottom: FOOTER_HEIGHT }}
      className='mt-6 flex justify-center'
    >
      <EditInviteForm
        onComplete={async (newInvite) => {
          const { response, error } = await addNewInvite(newInvite);

          if (response) {
            const { invite_id: inviteId } = response;
            // navigate to the invite conversation page
            navigate(`/i/${inviteId}`);
            return;
          }
        }}
      />
    </div>
  );
}

export { NewInviteScreen };
