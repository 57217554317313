import React from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';
import {
  HomeIcon,
  PlusCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import {
  HomeIcon as HomeIconFilled,
  PlusCircleIcon as PlusCircleIconFilled,
  UsersIcon as UsersIconFilled,
} from '@heroicons/react/24/solid';

export const BOTTOM_NAV_Z_INDEX = 50;
const pathsToShow = ['/', '/new', '/contacts'];

export const showBottomNav = () => {
  const href = window.location.href;
  const path = href.split(window.location.origin)[1];
  return path !== '/login' && pathsToShow.indexOf(path) !== -1;
};

const navOptions = [
  {
    label: 'Home',
    icon: <HomeIcon />,
    activeIcon: <HomeIconFilled />,
    path: '/',
  },
  {
    label: 'New',
    icon: <PlusCircleIcon />,
    activeIcon: <PlusCircleIconFilled />,
    path: '/new',
  },
  {
    label: 'Contacts',
    icon: <UsersIcon />,
    activeIcon: <UsersIconFilled />,
    path: '/contacts',
  },
];

const getInitialValue = (path) => {
  if (['/', ''].includes(path)) {
    return '/';
  }
  if ('/new'.includes(path)) {
    return '/new';
  }
  if ('/contacts'.includes(path)) {
    return '/contacts';
  }
  return '/';
};

const NavButton = ({ option }) => {
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const isActive = pathname === option.path;
  return (
    <button
      key={option.path}
      onClick={() => {
        navigate(option.path);
      }}
      className='flex-1 flex items-center justify-center p-2'
    >
      <span className='w-10 h-10 mb-2 text-zebraTheme  group-hover:text-blue-600 dark:group-hover:text-blue-500'>
        {isActive ? option.activeIcon : option.icon}
      </span>
    </button>
  );
};

function BottomNav() {
  // for bottom nav to re-render when the path changes
  const navigate = useNavigate(); // needed so we re-render when the path changes

  const auth = React.useContext(authContext);
  if (!auth) {
    return null;
  }

  if (!showBottomNav(window.location.href)) {
    return null;
  }

  return (
    <div
      id='bottom-nav'
      className={`z-[${BOTTOM_NAV_Z_INDEX}] fixed bottom-0 left-0 w-full h-16 bg-zebraTheme-light rounded-t-xl`}
    >
      <div className='flex h-full max-w-lg mx-auto font-medium'>
        {navOptions.map((option) => {
          return <NavButton key={option.path} option={option} />;
        })}
      </div>
    </div>
  );
}

export { BottomNav };
