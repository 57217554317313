import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { authContext } from '../contexts/AuthContext';
import { useNetworkManager } from '../util/Network';
import { GoogleIcon } from '../components/icons/GoogleIcon';
import { AppleIcon } from '../components/icons/AppleIcon';

import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  getAuth as getFirebaseAuth,
  signInWithPopup,
  signInWithCredential,
} from 'firebase/auth';
import { isOnIOS, isOnWeb, sendNativeMessage } from '../util/NativeBridge';

const FirebaseSignIn = () => {
  const { shareId } = useParams();
  const auth = getFirebaseAuth();

  const { firebaseUser, setFirebaseUser, setAuth } = useContext(authContext);
  const navigate = useNavigate();
  const { makeGetRequest } = useNetworkManager();

  // useEffect(() => {
  //   const appleProvider = new OAuthProvider('apple.com');
  //   appleProvider.addScope('email');
  //   appleProvider.addScope('name');

  //   const twitterProvider = new TwitterAuthProvider();
  // }, []);

  if (!firebaseUser === false) {
    return null;
  }

  const handleGoogleButtonClicked = async () => {
    let result;
    if (isOnWeb()) {
      const googleProvider = new GoogleAuthProvider();
      result = await signInWithPopup(auth, googleProvider);
    } else if (isOnIOS()) {
      const nativeMessage = {
        namespace: 'auth',
        action: 'doGoogleSignOn',
      };
      const nativeResult = await sendNativeMessage(nativeMessage);
      if (nativeResult) {
        const { idToken, accessToken } = nativeResult;
        const credential = GoogleAuthProvider.credential(idToken, accessToken);
        result = await signInWithCredential(auth, credential);
      }
    } else {
      // TODO: Android
    }

    if (result) {
      console.log('result', result);
      const { response, error } = await makeGetRequest(
        `auth/isRegisteredFirebaseUser/${result.user.uid}${shareId ? '/' + shareId : ''}`
      );
      if (response?.isRegistered) {
        setAuth(response.user, result.user.accessToken);
        setFirebaseUser(result.user);
        navigate('/');
        // they're authed! move em on
      } else {
        navigate(`/finishSetup/${shareId || ''}`);
        // they need to finish registration
      }
    }
  };

  const handleAppleButtonClicked = async () => {
    if (isOnWeb()) {
      const appleProvider = new OAuthProvider('apple.com');
      appleProvider.addScope('email');
      appleProvider.addScope('name');
      const result = await signInWithPopup(auth, appleProvider);
    } else if (isOnIOS()) {
      // TODO
    } else {
      // TODO: Android
    }
  };

  const handleFacebookButtonClicked = async () => {
    // TODO: Implement Facebook login
    if (isOnWeb()) {
      const facebookProvider = new OAuthProvider('facebook.com');
      const result = await signInWithPopup(auth, facebookProvider);
    } else if (isOnIOS()) {
    } else {
      // TODO: Android
    }
  };

  return (
    <div className='bg-white rounded-md p-5 mt-5 mx-3 flex flex-col items-center '>
      <div className='flex flex-col items-center w-full gap-y-4'>
        <div className='flex flex-col gap-y-0'>
          <p className='text-lg'>Create an Account </p>
        </div>
        <button
          onClick={handleGoogleButtonClicked}
          className='flex flex-row w-3/4 items-center justify-center p-3 rounded-md gap-x-2 border-gray-300 border bg-white font-medium'
        >
          <span>
            <GoogleIcon />
          </span>
          <p className='flex-1'>Register with Google</p>
        </button>
        <button
          onClick={handleAppleButtonClicked}
          className='flex flex-row w-3/4 items-center justify-center p-3 rounded-md gap-x-2 border-gray-300 border bg-white font-medium'
        >
          <span>
            <AppleIcon />
          </span>
          <p className='flex-1'>Register with Apple</p>
        </button>

        <p className='text-xs text-center'>
          You will be signed in if you already have an account
        </p>

        <p className='text-xs text-center mt-5'>
          By signing up to Zebra you agree to our{' '}
          <a className='text-orange-500 hover:text-orange-700' href='/privacy'>
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className='text-orange-500 hover:text-orange-700'
            href='/terms-and-conditions'
          >
            Terms and Conditions
          </a>
        </p>
      </div>
    </div>
  );
};

export { FirebaseSignIn };
