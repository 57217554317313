import React, { createContext } from 'react';
import { authContext } from './AuthContext';
import { useNetworkManager } from '../util/Network';

export const InvitesContext = createContext();

const { Provider } = InvitesContext;

export const useInvites = () => {
  const [invites, setInvites] = React.useState(undefined);

  const { user } = React.useContext(authContext);

  const { makeGetRequest, makePostRequest } = useNetworkManager();

  const fetchInvites = React.useCallback(async () => {
    const { response, error } = await makeGetRequest(`invites/list`);

    return { response, error };
  }, [user]);

  const refreshInvites = async () => {
    const { response, error } = await fetchInvites();

    if (response) {
      console.log('Refresh invites response:', response);
      setInvites(response);
    } else {
      console.error(error);
      // TODO: Log errors;
    }
  };

  const addNewInvite = async (newInviteDetails) => {
    const { response, error } = await makePostRequest(
      `invites/create`,
      newInviteDetails
    );

    return { response, error };
  };

  const editExistingInvite = async (invite) => {
    const { response, error } = await makePostRequest(
      `invites/edit/${invite.invite_id}`,
      invite
    );

    return { response, error };
  };

  const cancelInvite = async (inviteId) => {
    const { response, error } = await makePostRequest(
      `invites/cancel/${inviteId}`
    );

    return { response, error };
  };

  const acceptInvite = async ({ inviteId, userId }) => {
    const { response, error } = await makePostRequest(
      `invites/accept/${inviteId}`,
      {
        inviteId,
        userId,
      }
    );

    if (response.success) {
      console.log('Accepted invite:', response);
      refreshInvites();
    }

    return { response, error };
  };

  const declineInvite = async ({ inviteId, userId }) => {
    const { response, error } = await makePostRequest(
      `invites/decline/${inviteId}`,
      {
        inviteId,
        userId,
      }
    );

    if (response) {
      refreshInvites();
    }

    return { response, error };
  };

  return {
    invites,
    refreshInvites,
    addNewInvite,
    editExistingInvite,
    cancelInvite,
    acceptInvite,
    declineInvite,
  };
};

const InviteProvider = ({ children }) => {
  const inviteFns = useInvites();
  return <Provider value={{ ...inviteFns }}>{children}</Provider>;
};

export default InviteProvider;

const isInviteAccepted = (participant) => {
  return participant.status === 'confirmed';
};

const isInviteDeclined = (participant) => {
  return participant.status === 'declined';
};

const isInvitePending = (participant) => {
  return participant.status === 'pending';
};

const isInviteCancelled = (invite) => {
  return invite.cancelled_at_timestamp;
};

const isInviteInPast = (invite) => {
  return invite.start_date && new Date(invite.start_date) < new Date();
};

export {
  isInviteAccepted,
  isInviteDeclined,
  isInvitePending,
  isInviteCancelled,
  isInviteInPast,
};
