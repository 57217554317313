import { isOnWeb } from '../util/NativeBridge';
import { DownloadAppStoreIcon } from './icons/DownloadAppStoreIcon';
import { DownloadPlayStoreIcon } from './icons/DownloadPlayStoreIcon';

export const ButtonsToDownloadApp = ({ ctaText }) => {
  return (
    isOnWeb() && (
      <>
        <p className='text-xs'>{ctaText}</p>
        <div className='flex flex-row gap-x-2'>
          <DownloadAppStoreIcon />
          <DownloadPlayStoreIcon />
        </div>
      </>
    )
  );
};
